import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { UserResolve } from './core/resolve/user-resolve';
import { CallbackComponent } from './views/auth/callback/callback.component';
import { ErrorComponent } from './views/errors/error/error.component';
import { LoginComponent } from './views/login/login.component';
import { UserOrganisationResolver } from './views/user-management/resolvers/user-organisation.resolver';
import { UserWithoutOrgComponent } from './views/auth/user-without-org/user-without-org.component';
import { MainLayoutComponent } from "./core/layout/main-layout/main-layout.component";
import { CustomDashboardComponent } from "./views/scenario/reports/custom-dashboard/custom-dashboard.component";
import { BreadcrumbsMenuComponent } from "./core/breadcrumbs-menu/breadcrumbs-menu.component";

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      user: UserResolve,
    },
    children: [
      {
        path: '',
        redirectTo: 'products',
        runGuardsAndResolvers: 'always',
        pathMatch: 'full',
      },
      {
        path: 'products',
        loadChildren: () => import('./views/product/product.module').then((m) => m.ProductModule),
      },
      {
        path: 'custom-dashboard',
        component: MainLayoutComponent,
        title: 'titles.customDashboards',
        resolve: {
          userOrganisation: UserOrganisationResolver,
          sticky: () => false
        },
        children: [
          {
            path: '',
            component: BreadcrumbsMenuComponent,
            outlet: 'breadcrumbs'
          },
          {
            path: ':report',
            component: CustomDashboardComponent,
          },
        ]
      },
      {
        path: 'company-management',
        resolve: {
          userOrganisation: UserOrganisationResolver,
        },
        loadChildren: () => import('./views/user-management/user-management.module').then(
          (m) => m.UserManagementModule
        ),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'implicit/callback',
    component: CallbackComponent,
  },
  {
    path: 'user-without-org',
    component: UserWithoutOrgComponent,
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
