import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from "./app/core/app.constants";
import { AppConfig } from "./app/core/config/models/config";

fetch('/assets/config.json')
  .then((response) => response.json())
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic([{provide: APP_CONFIG, useValue: AppConfig.fromJson(config)}])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
